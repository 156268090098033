import React, { useState } from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Tab from "./Tab"

const Tabs = ({ tabs = [], setTab }) => {
  const [active, setActive] = useState(1)

  const handleClick = tab => {
    setActive(tab)
    setTab(tab)
  }
  return (
    <Wrapper>
      {tabs.length &&
        tabs.map((tab, index) => (
          <Tab
            active={active === index + 1}
            onClick={() => handleClick(index + 1)}
            key={tab}
          >
            {tab}
          </Tab>
        ))}
    </Wrapper>
  )
}

export default Tabs
