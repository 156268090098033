import React, { useState, useEffect } from "react"
import { updateCustomerAddress, createCustomerAddress } from "../../../lib/api"

//Styled components imports
import Title from "./Title"
import Wrapper from "./Wrapper"
import Container from "./Container"
import InputWrapper from "./InputWrapper"
import CompanyInfo from "./CompanyInfo"
import CheckBoxWrapper from "../../../shared-styled-components/CheckBoxWrapper"
import CheckBox from "../../../shared-styled-components/CheckBox"
import InputField from "../../../shared-styled-components/InputField"
import Button from "../../../shared-styled-components/Button"
import ErrorMessage from "../../../shared-styled-components/ErrorMessage"

const initState = {
  name: "",
  email: "",
  phone: "",
  // newPassword: "",
  // newPasswordConfirm: "",
  ic: "",
  dic: "",
  company: "",
  address1: "",
  city: "",
  country: "",
  zip: "",
}

const UserInfo = ({ address, accessToken }) => {
  const [formValues, setFormValues] = useState(initState)
  const [errors] = useState({})
  const [companyAddr, setCompanyAddr] = useState(false)

  useEffect(() => {
    setFormValues({ ...initState, ...address })
    if (address && address.company) {
      setCompanyAddr(true)
    }
  }, [address])

  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const handleClick = async () => {
    const newAddress = {
      firstName: formValues.name.split(" ")[0],
      lastName: formValues.name.split(" ")[1],
      phone: formValues.phone,
      address1: formValues.address1,
      city: formValues.city,
      country: formValues.country,
      company: formValues.company,
      zip: formValues.zip,
    }

    if (address.id) {
      const res = await updateCustomerAddress({
        address: newAddress,
        accessToken,
        id: address.id,
      })
      if (res.status === "200") {
        console.log("Ok")
      }
    } else {
      const res = await createCustomerAddress({
        address: newAddress,
        accessToken,
      })
      if (res.status === "200") {
        console.log("Ok")
      }
    }
  }

  return (
    <Wrapper>
      <Title>Nastavení mého účtu</Title>
      <Container>
        <div>Osobní údaje</div>
        <InputWrapper>
          <label htmlFor="name">Jméno a příjmení</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="name"
              type="text"
              value={formValues.name}
              onChange={handleChange}
            />
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
          </div>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="email">Email</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleChange}
            />
            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </div>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="phone">Telefon</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="phone"
              type="text"
              value={formValues.phone}
              onChange={handleChange}
            />
            {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
          </div>
        </InputWrapper>
        {/* <InputWrapper>
          <label htmlFor="newPassword">Nové heslo</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="newPassword"
              type="password"
              value={formValues.newPassword}
              onChange={handleChange}
            />
            {errors.newPassword && (
              <ErrorMessage>{errors.newPassword}</ErrorMessage>
            )}
          </div>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="newPasswordConfirm">Nové heslo (kontrola)</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="newPasswordConfirm"
              type="password"
              value={formValues.newPasswordConfirm}
              onChange={handleChange}
            />
            {errors.newPasswordConfirm && (
              <ErrorMessage>{errors.newPasswordConfirm}</ErrorMessage>
            )}
          </div>
        </InputWrapper> */}
      </Container>
      <Container>
        <div>Fakturační adresa</div>
        <CheckBoxWrapper>
          Nakupuji na firmu
          <input
            type="checkbox"
            onChange={e => setCompanyAddr(e.target.checked)}
            checked={companyAddr}
          />
          <CheckBox />
        </CheckBoxWrapper>
        <CompanyInfo show={companyAddr}>
          <InputWrapper>
            <label htmlFor="ic">IČ</label>
            <div style={{ position: "relative" }}>
              <InputField
                name="ic"
                type="text"
                value={formValues.ic}
                onChange={handleChange}
              />
              {errors.ic && <ErrorMessage>{errors.ic}</ErrorMessage>}
            </div>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="dic">DIČ</label>
            <div style={{ position: "relative" }}>
              <InputField
                name="dic"
                type="text"
                value={formValues.dic}
                onChange={handleChange}
              />
              {errors.dic && <ErrorMessage>{errors.dic}</ErrorMessage>}
            </div>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="company">Název společnosti</label>
            <div style={{ position: "relative" }}>
              <InputField
                name="company"
                type="text"
                value={formValues.company}
                onChange={handleChange}
              />
              {errors.company && <ErrorMessage>{errors.company}</ErrorMessage>}
            </div>
          </InputWrapper>
        </CompanyInfo>
        <InputWrapper>
          <label htmlFor="address1">Ulice a číslo domu</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="address1"
              type="text"
              value={formValues.address1}
              onChange={handleChange}
            />
            {errors.address1 && <ErrorMessage>{errors.address1}</ErrorMessage>}
          </div>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="city">Město</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="city"
              type="text"
              value={formValues.city}
              onChange={handleChange}
            />
            {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
          </div>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="psc">PSČ</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="zip"
              type="text"
              value={formValues.zip}
              onChange={handleChange}
            />
            {errors.zip && <ErrorMessage>{errors.zip}</ErrorMessage>}
          </div>
        </InputWrapper>
        <InputWrapper>
          <label htmlFor="country">Země</label>
          <div style={{ position: "relative" }}>
            <InputField
              name="country"
              type="text"
              value={formValues.country}
              onChange={handleChange}
            />
            {errors.country && <ErrorMessage>{errors.country}</ErrorMessage>}
          </div>
        </InputWrapper>
      </Container>
      <Button onClick={handleClick}>Uložit změny</Button>
    </Wrapper>
  )
}

export default UserInfo
