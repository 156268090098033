import React, { useEffect, useState } from "react"

import Wrapper from "./Wrapper"
import Product from "./Product"
import Title from "./Title"
import Shipping from "./Shipping"
import Summary from "./Summary"

const OrderDetails = ({ order }) => {
  const [date, setDate] = useState(null)
  useEffect(() => {
    setDate(new Date(order.processedAt).toLocaleDateString("cs-CZ"))
  }, [order.processedAt])

  return (
    <Wrapper>
      <Title bottom="15px">Objednávka {order.orderNumber}</Title>
      {/* <Title>Faktura xxxx</Title> */}
      <Title>Zboží v objednávce</Title>
      {order.lineItems.edges.map(product => (
        <Product data={product.node} key={product.node.id} />
      ))}

      {/* <Title>Platba</Title>
      <div>Kartou online</div> 
      <Title>Způsob dopravy</Title>
      <div>Osobní odběr</div> */}

      <Shipping>
        <Title>Doprava</Title>
        <Title>
          {order.totalShippingPriceV2.amount > 0
            ? order.totalShippingPriceV2.amount + " Kč"
            : "ZDARMA"}{" "}
        </Title>
      </Shipping>

      <hr />
      <Summary>
        <div>
          Cena bez DPH{" "}
          {(order.subtotalPrice - order.totalTaxV2.amount).toFixed(2)} Kč
        </div>
        <div>Cena s DPH {order.subtotalPrice} Kč</div>
      </Summary>

      <hr />

      <Title>Fakturacni adresa</Title>
      <div>
        {order.shippingAddress.formatted.map(line => (
          <div>{line}</div>
        ))}
      </div>

      <hr />
      <div>Vytvořeno</div>
      <div>{date}</div>
    </Wrapper>
  )
}

export default OrderDetails
