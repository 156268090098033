import React, { useEffect, useState } from "react"
import { Media } from "../../../../utils/Media"

//Styled components imports
import Link from "./Link"

const Order = ({ showOrder, order }) => {
  const [date, setDate] = useState(null)
  useEffect(() => {
    setDate(new Date(order.processedAt).toLocaleDateString("cs-CZ"))
  }, [order.processedAt])
  return (
    <>
      <Link onClick={() => showOrder(order)}>{order.orderNumber}</Link>
      <div>{date}</div>
      <div>{order.subtotalPrice} Kč</div>
      <Media greaterThanOrEqual="desktop">
        {/* <div>Faktura 2209257461</div> */}
        <div style={{ justifySelf: "center" }}>-</div>
        <div style={{ justifySelf: "end" }}>{order.fulfillmentStatus}</div>
      </Media>
    </>
  )
}

export default Order
