import styled from "styled-components"

const Title = styled.div`
  font-size: 16px;
  margin-top: 10px;
  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    font-size: 18px;
    margin-bottom: 10px;
  }

  @media (min-width: 1400px) {
    font-size: 20px;
  }
`

export default Title
