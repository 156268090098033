import styled from "styled-components"

const Link = styled.div`
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.text.darkGrey};
  }
`

export default Link
