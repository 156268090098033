import styled from "styled-components"

const Wrapper = styled.div`
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  font-size: 16px;
  border-bottom: 1px solid white;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 22px;
  }
`

export default Wrapper
