import styled from "styled-components"

const Summary = styled.div`
  text-align: right;
  div:first-child {
    color: ${props => props.theme.colors.text.darkGrey};
    margin-bottom: 5px;
  }
`

export default Summary
