import styled from "styled-components"

const CheckBoxWrapper = styled.label`
  margin: 20px 0 12px;
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ div::after {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: white;
    top: 3px;
    right: 3px;
  }
`

export default CheckBoxWrapper
