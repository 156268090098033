import styled from "styled-components"

const Wrapper = styled.div`
  font-size: 14px;

  @media (min-width: 1400px) {
    font-size: 18px;
    padding: 0 15px;
  }
`

export default Wrapper
