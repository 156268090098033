import styled from "styled-components"

const CheckBox = styled.div`
  height: 20px;
  width: 20px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.text.white};
  position: absolute;
  top: 5px;
  left: 0;
`

export default CheckBox
