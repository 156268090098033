import React, { useState } from "react"

//React components imports
import Order from "./Order"
import OrderDetails from "./OrderDetails"

//Styled components imports
import Wrapper from "./Wrapper"
import Message from "./Message"

const Orders = ({ orders }) => {
  const [showOrder, setShowOrder] = useState(null)
  return (
    <>
      {!showOrder ? (
        <>
          <Wrapper>
            {orders?.length &&
              orders.map(order => (
                <Order
                  showOrder={setShowOrder}
                  order={order.node}
                  key={order.node.id}
                />
              ))}
          </Wrapper>
          {!orders?.length && <Message>Nemáte žádný objednávky</Message>}
        </>
      ) : (
        <OrderDetails order={showOrder} />
      )}
    </>
  )
}

export default Orders
