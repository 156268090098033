import styled from "styled-components"

const Wrapper = styled.div`
  button {
    display: block;
    margin: auto;
  }

  span {
    font-size: 18px;
    margin-left: 10px;
  }
`

export default Wrapper
