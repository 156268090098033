import styled, { css } from "styled-components"

const active = css`
  background: white;
  color: black;
  cursor: default;

  &:hover {
    color: black;
  }
`

const Tab = styled.div`
  cursor: pointer;
  padding: 10px 15px;

  &:hover {
    color: ${props => props.theme.colors.text.darkGrey};
  }
  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 10px 20px;
  }

  ${props => props.active && active}
`

export default Tab
