import React from "react"

// React components imports

// Styled components imports
import Wrapper from "./Wrapper"
import Container from "./Container"
import Title from "./Title"
import Summary from "./Summary"
// import Description from "./Description"

const Product = ({ data }) => {
  return (
    <Wrapper>
      <Container>
        <img src={data.variant.image.src} alt={data.variant.image.altText} />
        <div>
          <Title>{data.title}</Title>
        </div>
        <Summary>
          <span>{data.quantity} ks</span>
          <span>{(data.variant.price * data.quantity).toFixed(2)} Kč</span>
        </Summary>
      </Container>
    </Wrapper>
  )
}

export default Product
