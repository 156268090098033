import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  font-size: 20px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-template-columns: repeat(5, auto);
  }
`

export default Wrapper
