import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import { getCustomerInfo } from "../../lib/api"

//React components imports
import Tabs from "../Tabs"
import UserInfo from "./UserInfo"
import Orders from "./Orders"

//Styled components imports
import Wrapper from "./Wrapper"

const ProfileContent = ({ idToken }) => {
  const [activeTab, setActiveTab] = useState(1)
  const [address, setAddress] = useState(null)
  const [orders, setOrders] = useState(null)

  useEffect(() => {
    const getData = async () => {
      const data = await getCustomerInfo(idToken)

      if (data?.addresses?.edges.length) {
        setAddress({ ...data.addresses.edges[0].node, email: data.email })
        setOrders([...data.orders.edges])
      }
    }
    if (idToken && idToken !== "undefined") {
      getData()
    } else {
      navigate("/")
    }
  }, [idToken])
  return (
    <div>
      <Tabs
        tabs={["Moje osobní údaje", "Historie objednávek"]}
        setTab={setActiveTab}
      />
      <Wrapper>
        {activeTab === 1 ? (
          <UserInfo address={address} accessToken={idToken} />
        ) : (
          <Orders orders={orders} />
        )}
      </Wrapper>
    </div>
  )
}

export default ProfileContent
