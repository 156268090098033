import styled from "styled-components"

const Container = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-gap: 0 10px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 150px 1fr 300px;
    grid-gap: 15px;
    align-items: center;
  }
`

export default Container
