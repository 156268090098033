import styled from "styled-components"

const Summary = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: end;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-column: auto;
  }
`

export default Summary
