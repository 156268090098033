import styled from "styled-components"

const Title = styled.div`
  margin: 5px 0;
  font-size: 16px;

  ${props => props.bottom && `margin-bottom: ${props.bottom}`}
`

export default Title
