import styled, { css } from "styled-components"

const show = css`
  max-height: 500px;
`

const CompanyInfo = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  ${props => props.show && show}
`

export default CompanyInfo
