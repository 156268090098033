import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie"
import { navigate } from "gatsby"

//React components imports
import Layout from "../components/Layout"
import ProfileContent from "../components/ProfileContent"
import Breadcrumbs from "../components/Breadcrumbs"

//Styled components imports
import Title from "../shared-styled-components/Title"

const ProfilPage = ({ location }) => {
  const [loggedIn, setLoggedIn] = useState(true)

  const [cookies] = useCookies()

  useEffect(() => {
    if (cookies.idToken) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [cookies.idToken])
  return (
    <Layout subtitle={"Můj účet"}>
      {loggedIn ? (
        <>
          <Breadcrumbs
            data={{
              path: location.pathname.replace("/en", ""),
              breadEnd: "Můj účet",
            }}
          />
          <Title>Můj účet</Title>
          <ProfileContent idToken={cookies.idToken} />
        </>
      ) : (
        navigate("/")
      )}
    </Layout>
  )
}

export default ProfilPage
